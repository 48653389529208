import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Pagination, Table, Dimmer, Loader, Modal, Form, Comment, Select, Grid, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, AccordionTitle, AccordionContent, Checkbox, Rating, Card, Container, Dropdown} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import { makeApiCalls } from '../redux/api';
import './LeadersMeetingDetailPage.css';
import { useTranslation } from 'react-i18next';
import { getUserProfile } from '../services/user';
import { PassageDiscoveryOutputDisplayComponent } from '../components/PassageDiscoveryOutputDisplayComponent';
import { getWordCount } from '../services/system';
import defaultProfilePic from '../assets/images/default_profile_pic.png';

export const LeadersMeetingDetailPage = ({match}) => {
    const { t, i18n } = useTranslation();

    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const dispatchTool = (toDo, ...args) => {
        dispatch(toDo(...args));
    }

    const [displayLoader, setDisplayLoader] = useState(true);
    const [leadersMeeting, setLeadersMeeting] = useState(null);
    const [expandedPassageDiscoveryCode, setExpandedPassageDiscoveryCode] = useState(null);
    const [creator, setCreator] = useState(null);

    useEffect(() => {
        getUserProfile(dispatchTool)
        .then((response) => {
            makeApiCalls([
                {
                    endpoint: 'MEMBER_LEADERS_MEETINGS_GET_DETAIL_BY_ID',
                    slugFragments: {
                        leaders_meeting_id: match.params.id
                    }
                }
            ]).then((res) => {
                // passage discovery
                if (res[0].data.id) {
                    setLeadersMeeting(res[0].data);

                    // find creator TL by email
                    console.log(res[0].data.bsf_class.class_leaders);
                    var creatorTL = res[0].data.bsf_class.class_leaders.find((classLeader) => classLeader.user.email === res[0].data.creation_mail_from);
                    console.log(creatorTL);
                    setCreator(creatorTL);
                } else {
                    // invalid passage discovery, bring to start page
                    history.push('/my-teams');
                }

                setDisplayLoader(false);
            }).catch((err) => {
                setDisplayLoader(false);
            });
        })
        .catch((err) => {
            setDisplayLoader(false);
        })
    }, []);

    const onClickPassageDiscoveryAccordion = (passageDiscoveryCode) => {
        if (expandedPassageDiscoveryCode === passageDiscoveryCode) {
            setExpandedPassageDiscoveryCode(null);
        } else {
            setExpandedPassageDiscoveryCode(passageDiscoveryCode);
        }
    }

    return (
        <ResponsiveContainer
            headerActiveTab="My Teams"
        >
            <>
                <Dimmer active={displayLoader}>
                    <Loader>Loading</Loader>
                </Dimmer>
                {
                    leadersMeeting && leadersMeeting.status != 'Draft' &&
                    <>
                        <h2>{leadersMeeting.title}</h2>
                        {/* <Header attached='top'>
                            {
                                creator ?
                                <List relaxed animated verticalAlign='middle'>
                                    <List.Item>
                                        <Image avatar src={creator.user.avatar?.path || defaultProfilePic} />
                                        <List.Content>
                                            <List.Header>{creator.user.name} {creator.user.surname}</List.Header>
                                            <List.Description>
                                                {creator.leadership_role}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                                :
                                <List relaxed animated verticalAlign='middle'>
                                    <List.Item>
                                        <Image avatar src={defaultProfilePic} />
                                        <List.Content>
                                            <List.Header>{leadersMeeting.creation_mail_from}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            }
                        </Header>
                        <Segment>

                        </Segment> */}
                        <Tab panes={[
                            { menuItem: 'Passage Discoveries', render: () => 
                                <Tab.Pane>
                                    <Accordion styled fluid>
                                        {
                                            leadersMeeting.passage_discoveries.map((passageDiscovery, index) => {
                                                return (
                                                    <>
                                                        <AccordionTitle
                                                            active={expandedPassageDiscoveryCode === passageDiscovery.code}
                                                            index={index}
                                                            onClick={() => onClickPassageDiscoveryAccordion(passageDiscovery.code)}
                                                            className="leaders-meeting-passage-discovery-accordion-title-container"
                                                        >
                                                            <Icon name='dropdown' />
                                                            <List relaxed animated verticalAlign='middle'>
                                                                <List.Item>
                                                                    <Image avatar src={passageDiscovery.user.avatar?.path || defaultProfilePic} />
                                                                    <List.Content>
                                                                        <List.Header>{passageDiscovery.user.name} {passageDiscovery.user.surname}</List.Header>
                                                                        {
                                                                            passageDiscovery.user.class_leaders.length > 0 &&
                                                                            <List.Description>
                                                                                {passageDiscovery.user.class_leaders[0].leadership_role}
                                                                            </List.Description>
                                                                        }
                                                                    </List.Content>
                                                                </List.Item>
                                                            </List>
                                                        </AccordionTitle>
                                                        <AccordionContent active={expandedPassageDiscoveryCode === passageDiscovery.code}>
                                                            <PassageDiscoveryOutputDisplayComponent 
                                                                passageDiscovery={passageDiscovery} 
                                                                contents={passageDiscovery.content}
                                                                divisions={passageDiscovery.division}
                                                                subjectSentenceDescription={passageDiscovery.subject_sentence}
                                                                subjectSentenceDescriptionWordCount={getWordCount(passageDiscovery.subject_sentence)}
                                                                aimDescription={passageDiscovery.aim}
                                                                applications={passageDiscovery.application}
                                                            />
                                                        </AccordionContent>
                                                    </>
                                                );
                                            })
                                        }
                                    </Accordion>
                                </Tab.Pane>
                            }
                        ]} />
                    </>
                }
            </>
        </ResponsiveContainer>
    );
};