import { createMedia } from '@artsy/fresnel';
import React, { useState, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Popup, Message, Select, Grid, Segment, Header, Button, TextArea, List, Divider, Label, Icon, Accordion, Sidebar, Confirm, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types'
import '../pages/PassageDiscoveryBuilderEditPage.css';
import { makeApiCalls } from '../redux/api';
import { useSelector } from 'react-redux';
import Hotkeys from 'react-hot-keys';
import {PassageDiscoveryBuilderDivisionListDisplay} from '../components/PassageDiscoveryBuilderDivisionListDisplay';

// division structure in passage discovery:
// {
//     scopes: [
//         {
//             scope_book: (some book name)
//             scope_book_number: (some book number)
//             scope_from_verse: (some verse key),
//             scope_to_verse: (some verse key),
//         }
//     ],
//     description: (some string)
// }

export const PassageDiscoveryBuilderEditPageDivisionSection = ({passageDiscovery, setPassageDiscovery, contents, divisions, setDivisions, applications, setApplications, onSaveFailed, changeTab}) => {
    const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
            mobile: 0,
            tablet: 768,
            computer: 1024,
        },
    })

    const user = useSelector(state => state.user);
    const [displayingScopeIndex, setDisplayingScopeIndex] = useState();

    const [divisionSelectedFromContentIndex, setDivisionSelectedFromContentIndex] = useState();
    const [divisionSelectedToContentIndex, setDivisionSelectedToContentIndex] = useState();
    const [divisionDescription, setDivisionDescription] = useState();
    const [displayDivisionConfirmBox, setDisplayDivisionConfirmBox] = useState(false);
    const [selectedSavedDivisionIndex, setSelectedSavedDivisionIndex] = useState();
    const [expandDivisionTitle, setExpandDivisionTitle] = useState(false);

    const [saveDivisionButtonColor, setSaveDivisionButtonColor] = useState('yellow');
    const [saveDivisionButtonText, setSaveDivisionButtonText] = useState('Save');
    const [saveDivisionButtonLoading, setSaveDivisionButtonLoading] = useState(false);

    const [divisionRecordOpened, setDivisionRecordOpened] = useState(false);

    const [isDescriptionBoxOpened, setIsDescriptionBoxOpened] = useState(false);

    const [plumber, setPlumber] = useState(0);

    useEffect(() => {
        setDisplayingScopeIndex(0);
        setDivisionSelectedFromContentIndex(Array(passageDiscovery.scopes.length).fill(undefined));
        setDivisionSelectedToContentIndex(Array(passageDiscovery.scopes.length).fill(undefined));
    }, []);

    const getVerseKeys = () => {
        var toReturn = [];

        if (passageDiscovery && passageDiscovery.verses) {
            passageDiscovery.verses.map((scope, index) => {
                toReturn.push(Object.keys(passageDiscovery.verses[index][Object.keys(passageDiscovery.verses[index])[0]]));
            });
        }

        return toReturn;
    }

    /////// Division Related ////////
    const isIncludedInDivision = (contentIndex) => {
        var included = false;
        var verseKeys = getVerseKeys()[displayingScopeIndex];
        if (divisions) {
            divisions.map((division, index) => {
                if (
                    division.scopes[displayingScopeIndex].scope_from_verse != undefined &&
                    division.scopes[displayingScopeIndex].scope_to_verse != undefined &&
                    contents[contentIndex].scopes[displayingScopeIndex].scope_from_verse != undefined &&
                    contents[contentIndex].scopes[displayingScopeIndex].scope_to_verse != undefined &&
                    verseKeys.indexOf(contents[contentIndex].scopes[displayingScopeIndex].scope_from_verse) >= verseKeys.indexOf(division.scopes[displayingScopeIndex].scope_from_verse) &&
                    verseKeys.indexOf(contents[contentIndex].scopes[displayingScopeIndex].scope_to_verse) <= verseKeys.indexOf(division.scopes[displayingScopeIndex].scope_to_verse)
                ) {
                    included = true;
                }
            });
        }
        return included;
    }

    const onClickDivisionContent = (index) => {
        var tmpDivisionSelectedFromContentIndex = divisionSelectedFromContentIndex;
        var tmpDivisionSelectedToContentIndex = divisionSelectedToContentIndex;
        if (divisionSelectedFromContentIndex[displayingScopeIndex] !== undefined) {
            if (divisionSelectedToContentIndex[displayingScopeIndex] !== undefined) {
                // Case 1: both from and to contents are selected
                // if it is < from content, update from content
                // if it is == from content, set to content to none
                // if it is > from content and < to content, update to content
                // if it is == to content, unselect to content and update to index - 1
                // if it is > to content, update to content
                if (index < divisionSelectedFromContentIndex[displayingScopeIndex]) {
                    // setDivisionSelectedFromContentIndex(index);
                    tmpDivisionSelectedFromContentIndex[displayingScopeIndex] = index;
                } else if (index == divisionSelectedFromContentIndex[displayingScopeIndex]) {
                    // setDivisionSelectedToContentIndex(undefined);
                    tmpDivisionSelectedToContentIndex[displayingScopeIndex] = undefined;
                } else if (index > divisionSelectedFromContentIndex[displayingScopeIndex] && index < divisionSelectedToContentIndex[displayingScopeIndex]) {
                    // setDivisionSelectedToContentIndex(index);
                    tmpDivisionSelectedToContentIndex[displayingScopeIndex] = index;
                } else if (index == divisionSelectedToContentIndex[displayingScopeIndex]) {
                    // setDivisionSelectedToContentIndex(index-1);
                    tmpDivisionSelectedToContentIndex[displayingScopeIndex] = index-1;
                } else {
                    // setDivisionSelectedToContentIndex(index);
                    tmpDivisionSelectedToContentIndex[displayingScopeIndex] = index;
                }

            } else {
                // Case 2: only from content is selected
                // if it is < from content, update from content and set the original from content as to content
                // if it is == from content, set from content to none
                // if it is > from content, update to content
                if (index < divisionSelectedFromContentIndex[displayingScopeIndex]) {
                    // setDivisionSelectedToContentIndex(divisionSelectedFromContentIndex);
                    tmpDivisionSelectedToContentIndex[displayingScopeIndex] = divisionSelectedFromContentIndex[displayingScopeIndex];
                    // setDivisionSelectedFromContentIndex(index);
                    tmpDivisionSelectedFromContentIndex[displayingScopeIndex] = index;
                } else if (index == divisionSelectedFromContentIndex[displayingScopeIndex]) {
                    // setDivisionSelectedFromContentIndex(undefined);
                    tmpDivisionSelectedFromContentIndex[displayingScopeIndex] = undefined;
                } else {
                    // setDivisionSelectedToContentIndex(index);
                    tmpDivisionSelectedToContentIndex[displayingScopeIndex] = index;
                }

            }
        } else {
            // Case 3: none is ever selected
            // set it to be from content
            // setDivisionSelectedFromContentIndex(index);
            tmpDivisionSelectedFromContentIndex[displayingScopeIndex] = index;
        }

        setDivisionSelectedFromContentIndex(tmpDivisionSelectedFromContentIndex);
        setDivisionSelectedToContentIndex(tmpDivisionSelectedToContentIndex);
        setPlumber(plumber + 1);
    }

    const onChangeDivisionDescription = (e, data) => {
        setDivisionDescription(data.value);
    }

    const submitDivisionToBackend = (newDivision, newApplication, afterSaveSuccess, afterSaveFailed) => {
        var tmpPD = passageDiscovery;
        tmpPD.division = newDivision;
        tmpPD.application = newApplication;
        setPassageDiscovery(tmpPD);
        makeApiCalls(
            [
                {
                    endpoint: (user == undefined? 'PUBLIC_PASSAGE_DISCOVERY_UPDATE': 'MEMBER_PASSAGE_DISCOVERY_UPDATE'),
                    data: tmpPD
                }
            ]
        ).then((res) => {
            afterSaveSuccess(res)
            
        }).catch((err) => {
            afterSaveFailed(err)
        })
    }

    const onClickSaveDivision = () => {
        // parse selected division
        var fromContentIndex = divisionSelectedFromContentIndex;
        var toContentIndex = divisionSelectedToContentIndex;
        
        Array(passageDiscovery.scopes.length).fill(undefined).map((_, index) => {
            if (fromContentIndex[index] !== undefined) {
                if (toContentIndex[index] === undefined) {
                    toContentIndex[index] = fromContentIndex[index];
                }
            }
        })
        // if there is selectedSavedDivisionIndex, save to the original
        // if no selectedSavedDivisionIndex, push in new one
        if (!fromContentIndex.every(el => el == undefined)) {
            setSaveDivisionButtonLoading(true);
            var verseKeys = getVerseKeys();
            var tmp = divisions.slice();
            var tmpApplications = applications.slice();
            
            // construct new content
            var newDivision = {
                scopes: [],
                description: divisionDescription
            };

            fromContentIndex.map((fromIndex, index) => {
                newDivision.scopes[index] = {
                    scope_book: fromContentIndex[index]!= undefined ? passageDiscovery.scopes[index].scope_book : undefined,
                    scope_book_number: toContentIndex[index] != undefined ? passageDiscovery.scopes[index].scope_book_number : undefined,
                    scope_from_verse: fromContentIndex[index]!= undefined ? contents[fromContentIndex[index]].scopes[index].scope_from_verse : undefined,
                    scope_to_verse: toContentIndex[index] != undefined ? contents[toContentIndex[index]].scopes[index].scope_to_verse : undefined,
                };
            });
            
            // insert new content and sort
            if (selectedSavedDivisionIndex === undefined) {
                tmp.push(newDivision);
                // tmp.sort((a, b) => verseKeys.indexOf(a.scope_from_verse) > verseKeys.indexOf(b.scope_from_verse)? 1 : -1);
                tmp.sort((a, b) => {
                    var aFirstIndex = a.scopes.findIndex(el => el.scope_from_verse != undefined);
                    var bFirstIndex = b.scopes.findIndex(el => el.scope_from_verse != undefined);
                    // assume FirstIndex must be >= 0
                    if (aFirstIndex > bFirstIndex) {
                        return 1;
                    } else if (aFirstIndex < bFirstIndex) {
                        return -1;
                    } else {
                        return verseKeys[aFirstIndex].indexOf(a.scopes[aFirstIndex].scope_from_verse) > verseKeys[bFirstIndex].indexOf(b.scopes[bFirstIndex].scope_from_verse)? 1 : -1;
                    }
                });

                // update applications
                // if the scope exist in applications, do nothing
                // if the scope does not exist in applications, add scope
                var newApplication = {
                    scopes: newDivision.scopes,
                    description: ''
                };
                tmpApplications.push(newApplication);
                tmpApplications.sort((a, b) => {
                    var aFirstIndex = a.scopes.findIndex(el => el.scope_from_verse != undefined);
                    var bFirstIndex = b.scopes.findIndex(el => el.scope_from_verse != undefined);
                    // assume FirstIndex must be >= 0
                    if (aFirstIndex > bFirstIndex) {
                        return 1;
                    } else if (aFirstIndex < bFirstIndex) {
                        return -1;
                    } else {
                        return verseKeys[aFirstIndex].indexOf(a.scopes[aFirstIndex].scope_from_verse) > verseKeys[bFirstIndex].indexOf(b.scopes[bFirstIndex].scope_from_verse)? 1 : -1;
                    }
                });
            } else {
                tmp[selectedSavedDivisionIndex] = newDivision
                tmpApplications[selectedSavedDivisionIndex].scopes = newDivision.scopes
            }

            // save content to backend
            submitDivisionToBackend(
                tmp,
                tmpApplications,
                (res) => {
                    console.log(res);
                    setDivisions(tmp);
                    setDivisionSelectedFromContentIndex(Array(passageDiscovery.scopes.length).fill(undefined));
                    setDivisionSelectedToContentIndex(Array(passageDiscovery.scopes.length).fill(undefined));
                    setDivisionDescription('');
                    setSelectedSavedDivisionIndex(undefined);

                    setApplications(tmpApplications);

                    setSaveDivisionButtonLoading(false);
                    setSaveDivisionButtonColor('green');
                    setSaveDivisionButtonText('Saved!');
                    setTimeout(() => {
                        setSaveDivisionButtonColor('yellow');
                        setSaveDivisionButtonText('Save');
                    }, 2000);
                },
                (err) => {
                    console.log(err);
                    setSaveDivisionButtonLoading(false);
                    onSaveFailed();
                }
            );        
        }

        // setSaveDivisionButtonLoading(true);
        // var fromContentIndex = divisionSelectedFromContentIndex;
        // var toContentIndex = divisionSelectedToContentIndex;
        // if (fromContentIndex !== undefined) {
        //     // if to content is not selected, that means only one content was selected
        //     // make to content == from content
        //     if (toContentIndex === undefined) {
        //         toContentIndex = fromContentIndex;
        //     }
        //     var verseKeys = getVerseKeys();
        //     var tmp = divisions.slice();
        //     var tmpApplications = applications.slice();
        //     var newDivision = {
        //         scope_from_verse: contents[fromContentIndex].scope_from_verse,
        //         scope_to_verse: contents[toContentIndex].scope_to_verse,
        //         description: divisionDescription
        //     };
        //     if (selectedSavedDivisionIndex === undefined) {
        //         tmp.push(newDivision);
        //         tmp.sort((a, b) => verseKeys.indexOf(a.scope_from_verse) > verseKeys.indexOf(b.scope_from_verse)? 1 : -1);
                
        //         // update applications 
        //         // if the scope exist in applications, do nothing
        //         // if the scope does not exist in applications, add scope
        //         var newApplication = {
        //             scope_from_verse: newDivision.scope_from_verse,
        //             scope_to_verse: newDivision.scope_to_verse,
        //             description: ''
        //         };
        //         console.log(tmpApplications);
        //         tmpApplications.push(newApplication);
        //         tmpApplications.sort((a, b) => verseKeys.indexOf(a.scope_from_verse) > verseKeys.indexOf(b.scope_from_verse)? 1 : -1);
        //     } else {
        //         tmp[selectedSavedDivisionIndex] = newDivision
        //     }
            
        //     // save division to backend
        //     submitDivisionToBackend(
        //         tmp,
        //         tmpApplications,
        //         (res) => {
        //             console.log(res);
        //             setDivisions(tmp);
        //             setDivisionSelectedFromContentIndex(undefined);
        //             setDivisionSelectedToContentIndex(undefined);
        //             setDivisionDescription('');
        //             setSelectedSavedDivisionIndex(undefined);

        //             setApplications(tmpApplications);

        //             setSaveDivisionButtonLoading(false);
        //             setSaveDivisionButtonColor('green');
        //             setSaveDivisionButtonText('Saved!');
        //             setTimeout(() => {
        //                 setSaveDivisionButtonColor('yellow');
        //                 setSaveDivisionButtonText('Save');
        //             }, 2000);
        //         },
        //         (err) => {
        //             console.log(err);
        //             setSaveDivisionButtonLoading(false);
        //             onSaveFailed();
        //         }
        //     );        
        // } else {
        //     // pop alert for missing info
        // }
    }

    const onClickClearDivision = () => {
        setDisplayDivisionConfirmBox(true);
    }

    const onCancelClearDivision = () => {
        setDisplayDivisionConfirmBox(false);
    }

    const onConfirmClearDivision = () => {
        // if there is selectedSavedDivisionIndex, clear the selected saved division
        // if no selectedSavedDivisionIndex, just clear the current work on division
        if (selectedSavedDivisionIndex !== undefined) {
            var tmp = divisions;
            tmp.splice(selectedSavedDivisionIndex, 1)
            setDivisions(tmp);

            // update applications 
            // if the scope exist in applications, remove scope
            // if the scope does not exist in applications, do nothing
            var tmpApplications = applications;
            tmpApplications.splice(selectedSavedDivisionIndex, 1);
            setApplications(tmpApplications);
        }
        setDivisionSelectedFromContentIndex(Array(passageDiscovery.scopes.length).fill(undefined));
        setDivisionSelectedToContentIndex(Array(passageDiscovery.scopes.length).fill(undefined));
        setDivisionDescription('');
        setSelectedSavedDivisionIndex(undefined);
        setDisplayDivisionConfirmBox(false);

        //save content to backend
        submitDivisionToBackend(
            tmp,
            tmpApplications,
            (res) => {

            },
            (err) => {

            }
        );
    }

    const onClickSavedDivision = (index) => {
        // if there is selectedSavedDivisionIndex and == index, turn off selection and set undefined
        // if there is selectedSavedDivisionIndex and != index, update selection
        // if no selectedSavedDivisionIndex, update selection
        if (
            index != undefined && 
            (selectedSavedDivisionIndex === undefined || (selectedSavedDivisionIndex !== undefined && selectedSavedDivisionIndex != index))
        ) {
            var savedDivision = divisions[index];
            var firstIndex = savedDivision.scopes.findIndex(el => el.scope_from_verse != undefined);
            var verseKeys = getVerseKeys();
            if (savedDivision) {
                var tmpDivisionSelectedFromContentIndex = divisionSelectedFromContentIndex;
                var tmpDivisionSelectedToContentIndex = divisionSelectedToContentIndex;
                savedDivision.scopes.map((scope, index) => {
                    tmpDivisionSelectedFromContentIndex[index] = contents.findIndex((item) => {return item.scopes[index].scope_from_verse == scope.scope_from_verse});
                    tmpDivisionSelectedToContentIndex[index] = contents.findIndex((item) => {return item.scopes[index].scope_to_verse == scope.scope_to_verse});
                })
                setDivisionSelectedFromContentIndex(tmpDivisionSelectedFromContentIndex);
                setDivisionSelectedToContentIndex(tmpDivisionSelectedToContentIndex);
                setDivisionDescription(savedDivision.description);
                setSelectedSavedDivisionIndex(index);

                if (firstIndex == displayingScopeIndex) {
                    var targetContent = document.getElementById(`content-index-${displayingScopeIndex}-${contents.findIndex((item) => {return item.scope_from_verse == savedDivision.scope_from_verse})}`);
                    if (targetContent) {
                        targetContent.scrollIntoView({behavior:'smooth', block: 'start', inline: "nearest"});
                    }
                }
            }
        } else {
            setDivisionSelectedFromContentIndex(Array(passageDiscovery.scopes.length).fill(undefined));
            setDivisionSelectedToContentIndex(Array(passageDiscovery.scopes.length).fill(undefined));
            setDivisionDescription('');
            setSelectedSavedDivisionIndex(undefined);
        }
    }

    const onClickDivisionTitle = () => {
        var tmp = expandDivisionTitle;
        setExpandDivisionTitle(!tmp);
    }

    const isContentVisible = function (ele) {
        var container = document.getElementById('content-container');
        
        const eleTop = ele.offsetTop;
        const eleBottom = eleTop + ele.clientHeight;
    
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.clientHeight;
    
        // The element is fully visible in the container
        return (
            (eleTop >= containerTop && eleBottom <= containerBottom)
            // Some part of the element is visible in the container
            // (eleTop < containerTop && containerTop < eleBottom) ||
            // (eleTop < containerBottom && containerBottom < eleBottom)
        );
    };

    const getScopeOptions = () => {
        var options = [];

        if (passageDiscovery && passageDiscovery.scopes) {
            passageDiscovery.scopes.map((scope, index) =>{
                options.push( { key: index, value: index, text: `${scope.scope_book} ${scope.scope_from_chapter}:${scope.scope_from_verse} - ${scope.scope_to_chapter}:${scope.scope_to_verse}` },);
            });
        }
        return options;
    }

    return (
        <Hotkeys 
            keyName="ctrl+s,command+s,shift+up,shift+down,ctrl+pageup,ctrl+pagedown,command+pageup,command+pagedown,ctrl+enter,command+enter,ctrl+backspace,command+backspace" 
            filter={(event) => {
                return true;
            }}
            onKeyDown={(keyName, e, handle) => {
                e.preventDefault();
                if (keyName == 'ctrl+s' || keyName == 'command+s') {
                    onClickSaveDivision();
                }
                if (keyName == 'ctrl+backspace' || keyName == 'command+backspace') {
                    onClickSavedDivision(undefined);
                }
                if (keyName == 'shift+up') {
                    var indexToBeClicked;
                    if (divisionSelectedFromContentIndex[displayingScopeIndex] == undefined) {
                        if (divisions.length > 0) {
                            var lastSelectedIndex;
                            for (var i = 0; i < contents.length; i++) {
                                if (!isIncludedInDivision(i) && contents[i].scopes[displayingScopeIndex].scope_book_number != undefined) {
                                    lastSelectedIndex = i;
                                    break;
                                }
                            }
                            if (lastSelectedIndex != undefined && lastSelectedIndex < contents.length) {
                                indexToBeClicked = lastSelectedIndex;
                            } 
                        } else {
                            indexToBeClicked = 0;
                        }
                    } else {
                        if (divisionSelectedToContentIndex[displayingScopeIndex] == undefined) {
                            if (divisionSelectedFromContentIndex[displayingScopeIndex] > 0) {
                                indexToBeClicked = divisionSelectedFromContentIndex[displayingScopeIndex] - 1;
                            } else {
                                indexToBeClicked = divisionSelectedFromContentIndex[displayingScopeIndex];
                            }
                        } else {
                            if (divisionSelectedToContentIndex[displayingScopeIndex] - 1 >= 0) {
                                indexToBeClicked = divisionSelectedToContentIndex[displayingScopeIndex] - 1;
                            }
                        }
                    }

                    if (indexToBeClicked != undefined) {
                        onClickDivisionContent(indexToBeClicked);
                        var target = document.getElementById(`content-index-${displayingScopeIndex}-${indexToBeClicked}`);
                        if (target && !isContentVisible(target)) {
                            target.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                        }
                    }
                }
                if (keyName == 'shift+down') {
                    var indexToBeClicked;
                    if (divisionSelectedFromContentIndex[displayingScopeIndex] == undefined) {
                        if (divisions.length > 0) {
                            var lastSelectedIndex;
                            for (var i = 0; i < contents.length; i++) {
                                if (!isIncludedInDivision(i) && contents[i].scopes[displayingScopeIndex].scope_book_number != undefined) {
                                    lastSelectedIndex = i;
                                    break;
                                }
                            }
                            console.log(lastSelectedIndex);
                            if (lastSelectedIndex != undefined && lastSelectedIndex < contents.length) {
                                indexToBeClicked = lastSelectedIndex;
                            } 
                        } else {
                            indexToBeClicked = 0;
                        }
                    } else {
                        if (divisionSelectedToContentIndex[displayingScopeIndex] == undefined) {
                            if (divisionSelectedFromContentIndex[displayingScopeIndex] + 1 < contents.length) {
                                indexToBeClicked = divisionSelectedFromContentIndex[displayingScopeIndex] + 1;
                            }
                        } else {
                            if (divisionSelectedToContentIndex[displayingScopeIndex] + 1 < contents.length) {
                                indexToBeClicked = divisionSelectedToContentIndex[displayingScopeIndex] + 1;
                            }
                        }
                    }

                    if (indexToBeClicked != undefined) {
                        onClickDivisionContent(indexToBeClicked);
                        var target = document.getElementById(`content-index-${displayingScopeIndex}-${indexToBeClicked}`);
                        if (target && !isContentVisible(target)) {
                            target.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                        }
                    }
                }
                if (keyName == 'ctrl+pageup' || keyName == 'command+pageup') {
                    changeTab(0);
                }
                if (keyName == 'ctrl+pagedown' || keyName == 'command+pagedown') {
                    changeTab(2);
                }
                if (keyName == 'ctrl+enter' || keyName == 'command+enter') {
                    var targets = document.getElementsByClassName('division-edit-desktop-textarea');
                    if (targets.length > 0) {
                        targets[0].focus();
                        targets[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                    }
                }
            }}
        >  
            <Confirm
                    content="Are you sure to clear the current content selection / division?"
                    open={displayDivisionConfirmBox}
                    onCancel={onCancelClearDivision}
                    onConfirm={onConfirmClearDivision}
            />
            <Accordion>
                <Accordion.Title
                    active={expandDivisionTitle}
                    index={0}
                    onClick={onClickDivisionTitle}
                >
                    <Header size="medium"><Icon name='dropdown' />DIVISIONS – Summary sentences</Header>
                </Accordion.Title>
                <Accordion.Content active={expandDivisionTitle}>
                    <p>
                        The organization of the content helps you remember the details and recognize the progression within the passage.
                    </p>
                    <p>
                        Reduce content list to an even shorter list, dividing the passage into two to four sections that follow the sequence of the passage and belong together.
                    </p>
                    <p>
                        Write a brief, grammatically correct sentence (with a subject and verb) to summarize each section of the passage.
                    </p>
                    <p>
                        Stick to the facts: The sentence reports the factual content of the passage, not your interpretation or conclusion
                    </p>
                    <p>
                        Write the verses after the sentence.
                    </p>
                </Accordion.Content>
            </Accordion>
            <Divider />
            <Header size="tiny"><Icon color="yellow" name="exclamation triangle" /> Instructions</Header>
            <List bulleted>
                <List.Item>
                Select desired <Label basic>Content</Label> for the scope of the division that you are constructing.
                </List.Item>
                <List.Item>
                Fill in your summary about the division and click <Label color="yellow">Save</Label> when appropriate. The division will be saved to <Label basic style={{'borderTop':"3px solid #fbbd08"}}>Divisions</Label> at page bottom.
                </List.Item>
                <List.Item>
                Click <Label>Clear</Label> when you want to drop the current draft of division.
                </List.Item>
                <List.Item>
                Click <Label color="yellow">View Saved Divisions</Label> to see the saved divisions. The list of <Label basic style={{'borderTop':"3px solid #fbbd08"}}>Divisions</Label> will slide in from the right. Click the backdrop to return.
                </List.Item>
                <List.Item>
                If you want to update any of the saved division, click on the desired ones in <Label basic style={{'borderTop':"3px solid #fbbd08"}}>Divisions</Label> at page bottom and edit. You can also remove it during editing by clicking <Label color="black">Delete</Label> at the page bottom.
                </List.Item>
                <List.Item>
                Click on <Label>Clear</Label> if you want to cancel editing.
                </List.Item>
            </List>
            <Divider />
            <Header size="tiny" textAlign="center">Content</Header>
            <Button color='yellow' fluid onClick={() => setDivisionRecordOpened(true)}>View Saved Divisions</Button>
            <Divider />
            <Sidebar.Pushable>
                <Sidebar
                    animation='overlay'
                    direction='right'
                    onHide={() => setDivisionRecordOpened(false)}
                    vertical
                    visible={divisionRecordOpened}
                >
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Segment color="yellow">
                                    <Header size="tiny" textAlign="center">Divisions</Header>
                                    <Divider />
                                    <PassageDiscoveryBuilderDivisionListDisplay
                                        passageDiscovery={passageDiscovery}
                                        divisions={divisions}
                                        ordered={true}
                                        selectedSavedDivisionIndex={selectedSavedDivisionIndex}
                                        onClickDivision={(index) => {onClickSavedDivision(index);setDivisionRecordOpened(false);}}
                                    ></PassageDiscoveryBuilderDivisionListDisplay>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>   
                </Sidebar>
    
                <Sidebar.Pusher dimmed={divisionRecordOpened}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div 
                                    id="content-container"
                                    className="verses-container"
                                    style={{'maxHeight': window.innerHeight*0.6, 'minHeight': window.innerHeight*0.2}}
                                >
                                    {
                                        contents && contents.length <= 0 &&
                                        <Header size="tiny">No content created yet.</Header>
                                    }
                                    <List divided>
                                        {
                                            divisionSelectedFromContentIndex !== undefined && 
                                            divisionSelectedToContentIndex !== undefined && 
                                            displayingScopeIndex !== undefined && 
                                            contents && contents.map((contentItem, index) => (
                                                <>
                                                {
                                                    contentItem.scopes[displayingScopeIndex].scope_book_number == passageDiscovery.scopes[displayingScopeIndex].scope_book_number &&
                                                    contentItem.scopes[displayingScopeIndex].scope_from_verse != undefined &&
                                                    contentItem.scopes[displayingScopeIndex].scope_to_verse != undefined &&
                                                    <List.Item 
                                                        key={index}
                                                        id={`content-index-${displayingScopeIndex}-${index}`}
                                                        style={{
                                                            'minHeight': '3rem', 
                                                            'paddingLeft': '6px', 
                                                            'paddingTop': '10px', 
                                                            'paddingBottom': '10px', 
                                                            'borderLeft': (isIncludedInDivision(index)?'3px solid black':'')
                                                        }} 
                                                        className={
                                                            divisionSelectedFromContentIndex[displayingScopeIndex] !== undefined?
                                                            (
                                                                divisionSelectedToContentIndex[displayingScopeIndex] !== undefined?
                                                                (
                                                                    divisionSelectedFromContentIndex[displayingScopeIndex] <= index && divisionSelectedToContentIndex[displayingScopeIndex] >= index ?
                                                                    'active-list-item'
                                                                    :
                                                                    ''
                                                                    )
                                                                :
                                                                (
                                                                    divisionSelectedFromContentIndex[displayingScopeIndex] == index?
                                                                    'active-list-item'
                                                                    :
                                                                    ''
                                                                )
                                                            )
                                                            :
                                                            ''
                                                        }
                                                        onClick={() => onClickDivisionContent(index)}
                                                    >
                                                        <List.Header>
                                                            <Label circular color="black" key="black">
                                                                {index+1}
                                                            </Label>
                                                            <Label>
                                                            {
                                                                passageDiscovery && passageDiscovery.scopes && passageDiscovery.scopes.map((scope, index) => (
                                                                    <>
                                                                        {
                                                                            contentItem.scopes[index].scope_from_verse != undefined && contentItem.scopes[index].scope_to_verse != undefined &&
                                                                            <>{contentItem.scopes[index].scope_book} {contentItem.scopes[index].scope_from_verse} - {contentItem.scopes[index].scope_to_verse}</>
                                                                        }
                                                                        {
                                                                            contentItem.scopes[index] && contentItem.scopes[index].scope_from_verse != undefined && contentItem.scopes[index + 1] && contentItem.scopes[index + 1].scope_from_verse != undefined &&
                                                                            <>, </>
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                            </Label>
                                                        </List.Header>
                                                        <br />
                                                        <List.Content>{contentItem.description}</List.Content>
                                                    </List.Item>
                                                }
                                                </>
                                            ))
                                        }
                                    </List>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {
                                    getScopeOptions().length > 1 &&
                                    <Message className="scopes-selector-container">
                                        <span><b>Passages: </b></span>
                                        {
                                            passageDiscovery && passageDiscovery.scopes && passageDiscovery.scopes[displayingScopeIndex] &&
                                            <Select options={getScopeOptions()} value={displayingScopeIndex} onChange={(e, data) => setDisplayingScopeIndex(data.value)}/>
                                        }
                                    </Message>
                                }
                                <Segment className="division-edit-desktop-container">
                                    <Label attached='top'>
                                        <b>Selection:&nbsp;</b> 
                                        {
                                            divisionSelectedFromContentIndex != undefined && !divisionSelectedFromContentIndex.every(el => el == undefined) &&
                                            <>
                                                {
                                                    passageDiscovery && passageDiscovery.scopes && passageDiscovery.scopes.map((scope, index) => (
                                                        <>
                                                            {
                                                                divisionSelectedFromContentIndex[index] != undefined && contents[divisionSelectedFromContentIndex[index]] && contents[divisionSelectedToContentIndex[index]] &&
                                                                // <>{scope.scope_book} {getVerseKeys()[index][divisionSelectedFromContentIndex[index]]} {divisionSelectedToContentIndex[index] != undefined ? '-':''} {getVerseKeys()[index][divisionSelectedToContentIndex[index]]}</>
                                                                <>{contents[divisionSelectedFromContentIndex[index]].scopes[index].scope_book} {contents[divisionSelectedFromContentIndex[index]].scopes[index].scope_from_verse} {divisionSelectedToContentIndex[index] != undefined ? `- ${contents[divisionSelectedToContentIndex[index]].scopes[index].scope_to_verse}`:''}</>
                                                            }
                                                            {
                                                                divisionSelectedFromContentIndex[index] != undefined && divisionSelectedFromContentIndex[index + 1] != undefined &&
                                                                <>, </>
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </>
                                        }
                                    </Label>
                                    <TextArea 
                                        className="division-edit-desktop-textarea"
                                        rows={4}
                                        value={divisionDescription}
                                        style={{
                                            'width': '100%',
                                            'border': 'none',
                                            'backgroundColor': 'transparent',
                                            'resize': 'none',
                                            'outline': 'none',
                                            'fontSize': 'max(1em, 16px)'

                                        }}
                                        placeholder='What is this division about?' 
                                        onChange={onChangeDivisionDescription}
                                        // onFocus={(e) => {
                                        //     var temp_value = e.target.value
                                        //     e.target.value = ''
                                        //     e.target.value = temp_value
                                        // }}
                                    />
                                </Segment>
                                <Segment className="division-edit-mobile-container">
                                    <TextArea 
                                        className="division-edit-mobile-textarea"
                                        rows={4}
                                        value={divisionDescription}
                                        style={{
                                            'width': '100%',
                                            'border': 'none',
                                            'backgroundColor': 'transparent',
                                            'resize': 'none',
                                            'outline': 'none',
                                            'fontSize': 'max(1em, 16px)'

                                        }}
                                        placeholder='What is this division about?' 
                                        onChange={onChangeDivisionDescription}
                                        // onFocus={(e) => {
                                        //     var temp_value = e.target.value
                                        //     e.target.value = ''
                                        //     e.target.value = temp_value
                                        // }}
                                    />
                                    <Modal
                                        onOpen={() => setIsDescriptionBoxOpened(true)}
                                        open={isDescriptionBoxOpened}
                                        trigger={
                                            <Icon className="division-edit-mobile-button" name="expand arrows alternate" />
                                        }
                                    >
                                        <Modal.Header>What is this division about?</Modal.Header>
                                        <Modal.Content>
                                            <Segment>
                                                <TextArea 
                                                    id="mobile-division-modal-textarea" 
                                                    rows={4}
                                                    defaultValue={divisionDescription}
                                                    style={{
                                                        'width': '100%',
                                                        'border': 'none',
                                                        'backgroundColor': 'transparent',
                                                        'resize': 'none',
                                                        'outline': 'none',
                                                        'fontSize': 'max(1em, 16px)'

                                                    }}
                                                    placeholder='What is this division about?' 
                                                    // onChange={onChangeDivisionDescription}
                                                    // onFocus={(e) => {
                                                    //     var temp_value = e.target.value
                                                    //     e.target.value = ''
                                                    //     e.target.value = temp_value
                                                    // }}
                                                />
                                            </Segment>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button color='yellow' onClick={(e) => {
                                                var textarea = document.getElementById("mobile-division-modal-textarea" );
                                                if (textarea) {
                                                    onChangeDivisionDescription(e, textarea);
                                                }
                                                setIsDescriptionBoxOpened(false);
                                            }}>
                                            Continue
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                                </Segment>
                                {
                                    (
                                        (divisionDescription != undefined && divisionDescription != null && divisionDescription != '') || 
                                        divisionSelectedFromContentIndex != undefined  && !divisionSelectedFromContentIndex.every(el => el == undefined)
                                    ) &&
                                    <p style={{'color': 'red'}}><Icon name="warning circle"/> You have not saved yet.</p>
                                }
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Button.Group fluid>
                                    <Button onClick={() => onClickSavedDivision(undefined)}>Cancel</Button>
                                    <Button.Or />
                                    <Button color={saveDivisionButtonColor} onClick={onClickSaveDivision} loading={saveDivisionButtonLoading}>{saveDivisionButtonText}</Button>
                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Button fluid color='black' onClick={onClickClearDivision}>Delete</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
            <Divider />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment color="yellow">
                            <Header size="tiny" textAlign="center">Saved Divisions</Header>
                            <Divider />
                            <PassageDiscoveryBuilderDivisionListDisplay
                                passageDiscovery={passageDiscovery}
                                divisions={divisions}
                                ordered={true}
                                selectedSavedDivisionIndex={selectedSavedDivisionIndex}
                                onClickDivision={onClickSavedDivision}
                            ></PassageDiscoveryBuilderDivisionListDisplay>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Hotkeys>
    );
};

PassageDiscoveryBuilderEditPageDivisionSection.propTypes = {
    passageDiscovery: PropTypes.object,
    setPassageDiscovery: PropTypes.func,
    contents: PropTypes.array,
    divisions: PropTypes.array,
    setDivisions: PropTypes.func,
    applications: PropTypes.array,
    setApplications: PropTypes.func,
    onSaveFailed: PropTypes.func
}
