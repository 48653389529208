import { makeApiCalls } from "../redux/api";

export let LEADERSHIP_ROLE_TEACHING_LEADER = 'Teaching Leader';
export let LEADERSHIP_ROLE_ADMIN_LEADER = 'Admin Leader';
export let LEADERSHIP_ROLE_GROUP_LEADER = 'Group Leader';

export function getLeadershipRoleOptions() {
    return [
        {key: LEADERSHIP_ROLE_GROUP_LEADER, text: LEADERSHIP_ROLE_GROUP_LEADER, value: LEADERSHIP_ROLE_GROUP_LEADER},
        {key: LEADERSHIP_ROLE_ADMIN_LEADER, text: LEADERSHIP_ROLE_ADMIN_LEADER, value: LEADERSHIP_ROLE_ADMIN_LEADER},
        {key: LEADERSHIP_ROLE_TEACHING_LEADER, text: LEADERSHIP_ROLE_TEACHING_LEADER, value: LEADERSHIP_ROLE_TEACHING_LEADER},
    ];
}

export function sortClassLeaders(classLeaders) {
    var roleScoreMapping = {};
    roleScoreMapping[LEADERSHIP_ROLE_TEACHING_LEADER] = 1;
    roleScoreMapping[LEADERSHIP_ROLE_ADMIN_LEADER] = 2;
    roleScoreMapping[LEADERSHIP_ROLE_GROUP_LEADER] = 3;
    return classLeaders.sort((a, b) => {
        return roleScoreMapping[a.leadership_role] + a.user.name > roleScoreMapping[b.leadership_role] + b.user.name ? 1 : -1;
    });
}

export function getBsfClassById(
    bsfClassId,
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'MEMBER_GET_BSF_CLASS_BY_ID',
                slugFragments: {
                    bsf_class_id: bsfClassId
                }
            }
        ],
    );
}
