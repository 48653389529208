import React, { useState, useEffect } from 'react'
import 'semantic-ui-css/semantic.min.css';
import { Header, List, Label, Container, Dimmer, Loader, Grid, Table, Pagination} from 'semantic-ui-react';
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { makeApiCalls } from '../redux/api';

export const MyTeamsLeadersMeetingTab = ({
    currentSelectedBsfClass,
    currentSelectedBsfClassLeaders
}) => {
    const history = useHistory();

    const [leadersMeetings, setLeadersMeetings] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [displayLoader, setDisplayLoader] = useState(false);

    useEffect(() => {
        if (currentSelectedBsfClass) {
            makeApiCalls([
                {
                    endpoint: 'MEMBER_LEADERS_MEETINGS_GET_BY_BSF_CLASS',
                    slugFragments: {
                        bsf_class_id: currentSelectedBsfClass.id
                    }
                }
            ]).then(res => {console.log(res);
                setLeadersMeetings(res[0].data.leaders_meetings);
                setTotalPages(res[0].data.total_pages);
            })
            .catch(err => {
    
            });
        }
    }, [currentSelectedBsfClass])

    const onPageChange = (event, data) => {
        makeApiCalls(
            [
                {
                    endpoint: 'MEMBER_LEADERS_MEETINGS_GET_BY_BSF_CLASS',
                    slugFragments: {
                        bsf_class_id: currentSelectedBsfClass.id
                    },
                    params: {
                        'page': data.activePage
                    }
                },
            ]
        ).then((res) => {
            setCurrentPage(data.activePage);
            setLeadersMeetings(res[0].data.leaders_meetings);
        }).catch((err) => {

        });
    }

    const onClickLeadersMeeting = (leadersMeetingId) => {
        history.push(`/my-teams/leaders-meeting/${leadersMeetingId}`);
    }

    return (
        <>
            <Dimmer active={displayLoader}>
                <Loader>Loading</Loader>
            </Dimmer>
            {
                totalPages &&
                <Table singleLine selectable color="yellow">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Title</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>From</Table.HeaderCell>
                            <Table.HeaderCell>Create Date</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            leadersMeetings.map((item, index) => (
                                <Table.Row key={index} onClick={() => onClickLeadersMeeting(item.id)}>
                                    <Table.Cell style={{textWrap: 'pretty'}}>
                                        {item.title}
                                    </Table.Cell>
                                    <Table.Cell>{item.status}</Table.Cell>
                                    <Table.Cell>
                                        {
                                            currentSelectedBsfClassLeaders.filter((leader) => leader.user.email == item.creation_mail_from).length <= 0 ?
                                            <>{item.creation_mail_from}</>
                                            :
                                            <>
                                            {
                                                currentSelectedBsfClassLeaders.filter((leader) => leader.user.email == item.creation_mail_from)?.map((leader) => (
                                                    <>{leader.user.name} {leader.user.surname}</>
                                                ))
                                            }
                                            </>                                                      
                                        }
                                    </Table.Cell>
                                    <Table.Cell>{item.created_at}</Table.Cell>
                                </Table.Row>
                            ))
                        }
                        {
                            leadersMeetings.length == 0 &&
                            <Table.Row>
                                <Table.Cell colSpan='4'>
                                    <Header size="tiny">No leaders meeting created yet.</Header>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'>
                                <Pagination
                                    activePage={currentPage}
                                    boundaryRange={1}
                                    onPageChange={onPageChange}
                                    size='mini'
                                    siblingRange={1}
                                    totalPages={totalPages}
                                    firstItem={false}
                                    lastItem={false}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            }
        </>
    );
};

MyTeamsLeadersMeetingTab.propTypes = {
    currentSelectedBsfClass: PropTypes.object,
    currentSelectedBsfClassLeaders: PropTypes.array
}
