import { makeApiCalls } from "../redux/api";

// search user by email
export function searchUserByEmail(
    email,
    bsfClassId,
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'MEMBER_SEARCH_USER_BY_EMAIL',
                data: {
                    "email": email,
                    "bsf_class_id": bsfClassId
                }
            }
        ],
        dispatchTool
    );
}

// create class leader
export function createClassLeader(
    bsfClassId,
    userId,
    leadershipRole,
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'MEMBER_CREATE_CLASS_LEADER',
                data: {
                    "bsf_class_id": bsfClassId,
                    "user_id": userId,
                    "leadership_role": leadershipRole
                }
            }
        ],
        dispatchTool
    );
}

// update class leader
export function updateClassLeader(
    classLeaderId,
    leadershipRole,
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'MEMBER_UPDATE_CLASS_LEADER',
                slugFragments: {
                    class_leader_id: classLeaderId
                },
                data: {
                    "leadership_role": leadershipRole
                }
            }
        ],
        dispatchTool
    );
}

// activate
export function activateClassLeader(
    classLeaderId,
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'MEMBER_ACTIVATE_CLASS_LEADER',
                slugFragments: {
                    class_leader_id: classLeaderId
                }
            }
        ],
        dispatchTool
    );
}

// deactivate
export function deactivateClassLeader(
    classLeaderId,
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'MEMBER_DEACTIVATE_CLASS_LEADER',
                slugFragments: {
                    class_leader_id: classLeaderId
                }
            }
        ],
        dispatchTool
    );
}

// delete class leader
export function deleteClassLeader(
    classLeaderId,
    dispatchTool=null
) {
    return makeApiCalls(
        [
            {
                endpoint: 'MEMBER_DELETE_CLASS_LEADER',
                slugFragments: {
                    class_leader_id: classLeaderId
                }
            }
        ],
        dispatchTool
    );
}