import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import { Pagination, Confirm, Grid, Dimmer, Search, Loader, Form, Modal, ModalHeader, ModalContent, ModalDescription, ModalActions, Segment, Header, Button, TextArea, Image, List, Progress, Divider, Label, Icon, Tab, Input, Accordion, Checkbox, Rating, Card, Container, Dropdown} from 'semantic-ui-react';
import { ResponsiveContainer } from '../containers/ResponsiveContainer';
import './MyTeamsPage.css';
import { getUserProfile } from '../services/user';
import { getBsfClassById, getLeadershipRoleOptions, LEADERSHIP_ROLE_TEACHING_LEADER, sortClassLeaders } from '../services/bsfclasses';
import defaultProfilePic from '../assets/images/default_profile_pic.png';
import { useForm } from "react-hook-form";
import { createClassLeader, deleteClassLeader, searchUserByEmail, updateClassLeader } from '../services/classleaders';
import _ from "lodash";
import { MyTeamsLeadersMeetingTab } from '../components/MyTeamsLeadersMeetingsTab';

export const MyTeamsPage = () => {
    const user = useSelector(state =>
        state.user
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const dispatchTool = (toDo, ...args) => {
        dispatch(toDo(...args));
    }

    // copy button
    const [copyButtonColor, setCopyButtonColor] = useState('yellow');
    const [copyButtonIcon, setCopyButtonIcon] = useState('copy');
    const [copyButtonText, setCopyButtonText] = useState('Copy');

    const {register, handleSubmit, setValue, getValues} = useForm({shouldUnregister: false});
    const [displayLoader, setDisplayLoader] = useState(false);

    const [bsfClasses, setBsfClasses] = useState([]);
    const [bsfClassOptions, setBsfClassOptions] = useState([]);
    const [currentSelectedBsfClass, setCurrentSelectedBsfClass] = useState(null);
    const [currentSelectedBsfClassLeaders, setCurrentSelectedBsfClassLeaders] = useState([]);
    const [refreshLeadersPulse, setRefreshLeadersPulse] = useState(0);
    const [detailedBsfClassLeader, setDetailedBsfClassLeader] = useState(null);

    const [saveButtonColor, setSaveButtonColor] = useState('yellow');
    const [saveButtonText, setSaveButtonText] = useState('Save');
    const [saveButtonLoading, setSaveButtonLoading] = useState(false);

    const [displayDeleteLeaderConfirm, setDisplayDeleteLeaderConfirm] = useState(false);

    const [displayAddLeaderModal, setDisplayAddLeaderModal] = useState(false);
    const [userToAdd, setUserToAdd] = useState(null);
    const [searchedUsers, setSearchedUsers] = useState([]);
    
    const [leaderSearchLoading, setLeaderSearchLoading] = useState(false);
    const [leaderSearchResult, setLeaderSearchResult] = useState([]);
    const [leaderSearchValue, setLeaderSearchValue] = useState('');



    useEffect(() => {
        // check for user
        setDisplayLoader(true);
        getUserProfile(dispatchTool)
        .then((res) => {
            var bsfClassesFromUserProfile = res[0].data.user.class_leaders.map((classLeader) => classLeader.bsf_class)
            setBsfClasses(bsfClassesFromUserProfile);
            setBsfClassOptions(bsfClassesFromUserProfile.map((bsfClass) => ({ key: bsfClass.id, value: bsfClass.id, text: bsfClass.name })));
            setCurrentSelectedBsfClass(bsfClassesFromUserProfile[0]);
            setDisplayLoader(false);
        })
        .catch((err) => {
            setDisplayLoader(false);
            // do sth
        });
    }, []);

    useEffect(() => {
        if (currentSelectedBsfClass) {
            if (!displayAddLeaderModal && !detailedBsfClassLeader) {
                setDisplayLoader(true);
            }
            getBsfClassById(currentSelectedBsfClass.id, dispatchTool)
            .then((res) => {
                // sort class leaders by leadership role and user.name
                setCurrentSelectedBsfClassLeaders(sortClassLeaders(res[0].data.class_leaders));
                if (!displayAddLeaderModal && !detailedBsfClassLeader) {
                    setDisplayLoader(false);
                }
            })
            .catch((err) => {
                if (!displayAddLeaderModal && !detailedBsfClassLeader) {
                    setDisplayLoader(false);
                }
            });
        }
    }, [currentSelectedBsfClass, refreshLeadersPulse]);

    const onClickCopyUrl = (url) => {
        // copy
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        // change button color
        setCopyButtonColor('green');
        setCopyButtonIcon('check');
        setCopyButtonText('Copied!');
        setTimeout(() => {
            setCopyButtonColor('yellow');
            setCopyButtonIcon('copy');
            setCopyButtonText('Copy');
        }, 2000)

    }

    const onLeaderSearchChange =  (e, data) => {
        console.log('data', data.value);
        searchUserByEmail(data.value, currentSelectedBsfClass.id, dispatchTool)
        .then((res) => {
            var users = res[0].data.users;
            // parse users to result format
            var result = users.map((user) => {
                return {
                    title: user.name + ' ' + user.surname,
                    description: user.email,
                    image: user.avatar?.path || defaultProfilePic,
                    user: user
                }
            });
            setLeaderSearchResult(result);
        })
        .catch((err) => {

        });
    }

    const onLeaderResultSelect = (e, data) => {
        setUserToAdd(data.result.user);
    }

    const onCreateNewClassLeader = (formData) => {
        setSaveButtonLoading(true);
        createClassLeader(currentSelectedBsfClass.id, userToAdd.id, formData.leadership_role, dispatchTool)
        .then((res) => {
            setSaveButtonLoading(false);
            setSaveButtonColor('green');
            setSaveButtonText('Saved!');
            setRefreshLeadersPulse((pulse) => {
                return pulse + 1;
            });
            setTimeout(() => {
                setSaveButtonColor('yellow');
                setSaveButtonText('Save');
                setDisplayAddLeaderModal(false);
                setDisplayAddLeaderModal(false);
                setUserToAdd(null);
            }, 2000);

        })
        .catch((err) => {
            setSaveButtonLoading(false);
            setSaveButtonColor('red');
            setSaveButtonText('Error!');
            setTimeout(() => {
                setSaveButtonColor('yellow');
                setSaveButtonText('Save');
            }, 2000);
        });
    }

    const onSelectBsfClass = (e, data) => {
        var bsfClass = bsfClasses.find((bsfClass) => bsfClass.id === data.value);
        setCurrentSelectedBsfClass(bsfClass || null);
    }

    const onClickClassLeader = (classLeader) => {
        setDetailedBsfClassLeader(classLeader);
    }

    const onProfileModalLeadershipRoleChange = (e, data) => {
        setValue('leadership_role', data.value);
    }

    const onSaveLeadershipInfo = (formData) => {
        setSaveButtonLoading(true);
        updateClassLeader(detailedBsfClassLeader.id, formData.leadership_role, dispatchTool)
        .then((res) => {
            setSaveButtonLoading(false);
            setSaveButtonColor('green');
            setSaveButtonText('Saved!');
            setRefreshLeadersPulse((pulse) => {
                return pulse + 1;
            })
            setTimeout(() => {
                setSaveButtonColor('yellow');
                setSaveButtonText('Save');
                setDetailedBsfClassLeader(null);
            }, 2000);
        })
        .catch((err) => {
            console.log('err', err);
            setSaveButtonLoading(false);
            setSaveButtonColor('red');
            setSaveButtonText('Error!');
            setTimeout(() => {
                setSaveButtonColor('yellow');
                setSaveButtonText('Save');
            }, 2000);
        });
    }

    const onDeleteLeader = () => {
        console.log('delete leader', detailedBsfClassLeader);
        if (detailedBsfClassLeader) {
            deleteClassLeader(detailedBsfClassLeader.id, dispatchTool)
            .then((res) => {
                setRefreshLeadersPulse((pulse) => {
                    return pulse + 1;
                });
                setDetailedBsfClassLeader(null);
                setDisplayDeleteLeaderConfirm(false);
            })
            .catch((err) => {
                // do sth
            });
        }
    }
    

    const isTeachingLeader = () => {
        return user?.class_leaders.find((classLeader) => classLeader.bsf_class_id === currentSelectedBsfClass?.id && classLeader.leadership_role === LEADERSHIP_ROLE_TEACHING_LEADER) !== undefined;
    }

    return (
        <ResponsiveContainer
            headerActiveTab="Class Management"
        >
            <Dimmer active={displayLoader}>
                <Loader>Loading</Loader>
            </Dimmer>
            {
                detailedBsfClassLeader &&
                <Modal
                    onClose={() => setDetailedBsfClassLeader(null)}
                    onOpen={() => console.log('do nothing on open')}
                    open={detailedBsfClassLeader}
                >
                    <ModalHeader>Profile</ModalHeader>
                    <ModalContent image>
                        <Container>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <div className="my-teams-modal-leader-container">
                                            <Image src={detailedBsfClassLeader?.user.avatar?.path || defaultProfilePic} className="my-teams-modal-avatar-display" avatar bordered size="small"/>
                                            <h2><b>{detailedBsfClassLeader?.user?.name} {detailedBsfClassLeader?.user?.surname}</b></h2>
                                            <p>{detailedBsfClassLeader?.user?.email}</p>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Form className='attached fluid segment' onSubmit={handleSubmit(onSaveLeadershipInfo)}>
                                            <Form.Group widths='equal'>
                                                <Form.Field width={8}>
                                                    <label htmlFor="name">Leadership Role</label>
                                                    <Dropdown
                                                        placeholder='Leadhip Role'
                                                        style={{'width': '100%'}}
                                                        fluid
                                                        search
                                                        selection
                                                        disabled={!isTeachingLeader()}
                                                        options={getLeadershipRoleOptions()}
                                                        onChange={onProfileModalLeadershipRoleChange}
                                                        defaultValue={detailedBsfClassLeader?.leadership_role}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                            {
                                                isTeachingLeader() &&
                                                <Button color={saveButtonColor} loading={saveButtonLoading} fluid>{saveButtonText}</Button>
                                            }
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    isTeachingLeader() && detailedBsfClassLeader &&
                                    <Grid.Row>
                                        <Grid.Column width={12}></Grid.Column>
                                        <Grid.Column width={4}>
                                            <Button color="red" onClick={() => setDisplayDeleteLeaderConfirm(true)}><i aria-hidden="true" className="trash alternate outline icon"></i></Button>
                                            <Confirm
                                                open={displayDeleteLeaderConfirm}
                                                onCancel={() => setDisplayDeleteLeaderConfirm(false)}
                                                onConfirm={() => onDeleteLeader()}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Container>
                    </ModalContent>
                </Modal>
            }
            <Container>
                <Dimmer active={displayLoader}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <Header as='h2' attached='top'>
                    <Dropdown
                        placeholder='Select Class'
                        fluid
                        search
                        selection
                        options={bsfClassOptions}
                        onChange={onSelectBsfClass}
                        value={currentSelectedBsfClass?.id}
                    />
                </Header>
                {
                    isTeachingLeader() &&
                    <Segment attached>
                        <Form>
                            <Form.Field>
                                <label>Bot Email</label>
                                <Input icon='at' iconPosition='left' value={currentSelectedBsfClass?.class_bot_email_address}
                                    action={{
                                        color: copyButtonColor,
                                        labelPosition: 'right',
                                        icon: copyButtonIcon,
                                        content: copyButtonText,
                                        onClick: () => onClickCopyUrl(currentSelectedBsfClass?.class_bot_email_address)
                                    }} 
                                />
                            </Form.Field>
                        </Form>
                    </Segment>
                }
                <br></br>
                <Tab panes={[
                    { menuItem: 'Leaders Meetings', render: () => 
                        <Tab.Pane>
                            <MyTeamsLeadersMeetingTab
                                currentSelectedBsfClass={currentSelectedBsfClass}
                                currentSelectedBsfClassLeaders={currentSelectedBsfClassLeaders}
                            />
                        </Tab.Pane>
                    },
                    { menuItem: 'Leaders', render: () => 
                        <Tab.Pane>
                            {
                                isTeachingLeader() &&
                                <Modal
                                    onClose={() => setDisplayAddLeaderModal(false)}
                                    onOpen={() => setDisplayAddLeaderModal(true)}
                                    open={displayAddLeaderModal}
                                    trigger={<Button fluid><i aria-hidden="true" className="plus icon"></i> Add Leader</Button>}
                                >
                                    <ModalHeader>Add Leader</ModalHeader>
                                    <ModalContent>
                                        <Header>Search For User</Header>
                                        <p>
                                            Enter email address to search for user
                                        </p>
                                        <Search
                                            loading={leaderSearchLoading}
                                            placeholder='Search...'
                                            onResultSelect={onLeaderResultSelect}
                                            onSearchChange={_.debounce(onLeaderSearchChange, 150)}
                                            results={leaderSearchResult}
                                        />
                                        {
                                            userToAdd &&
                                            <Container>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <div className="my-teams-modal-leader-container">
                                                                <Image src={userToAdd.avatar?.path || defaultProfilePic} className="my-teams-modal-avatar-display" avatar bordered size="small"/>
                                                                <h2><b>{userToAdd.name} {userToAdd.surname}</b></h2>
                                                                <p>{userToAdd.email}</p>
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Form className='attached fluid segment' onSubmit={handleSubmit(onCreateNewClassLeader)}>
                                                                <Form.Group widths='equal'>
                                                                    <Form.Field width={8}>
                                                                        <label htmlFor="name">Leadership Role</label>
                                                                        <Dropdown
                                                                            placeholder='Leadhip Role'
                                                                            style={{'width': '100%'}}
                                                                            fluid
                                                                            search
                                                                            selection
                                                                            options={getLeadershipRoleOptions()}
                                                                            onChange={onProfileModalLeadershipRoleChange}
                                                                        />
                                                                    </Form.Field>
                                                                </Form.Group>
                                                                <Button color={saveButtonColor} loading={saveButtonLoading} fluid>{saveButtonText}</Button>
                                                            </Form>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Container>
                                        }
                                    </ModalContent>
                                </Modal>
                            }
                            <List divided relaxed animated verticalAlign='middle'>
                                {
                                    currentSelectedBsfClassLeaders.map((classLeader) => (
                                        <List.Item key={classLeader.id} onClick={() => onClickClassLeader(classLeader)}>
                                            <Image avatar src={classLeader.user.avatar?.path || defaultProfilePic} />
                                            <List.Content>
                                                <List.Header>{classLeader.user.name} {classLeader.user.surname}</List.Header>
                                                <List.Description>
                                                    {classLeader.leadership_role}
                                                </List.Description>
                                            </List.Content>
                                        </List.Item>
                                    ))
                                }
                            </List>
                        </Tab.Pane> 
                    }
                ]} />
            </Container>
            
        </ResponsiveContainer>
    );
};